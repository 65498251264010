import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_ENVIRONMENT } from '$env/static/public';

const IS_PRODUCTION = PUBLIC_ENVIRONMENT === 'production';
const SAMPLE_RATE = IS_PRODUCTION ? 1 : 0;

Sentry.init({
	dsn: 'https://08c2a1b87fb4ed7f36c73873a40e66b3@o4504525224804352.ingest.us.sentry.io/4507892708737024',
	sampleRate: SAMPLE_RATE,
	tracesSampleRate: SAMPLE_RATE,
	replaysSessionSampleRate: SAMPLE_RATE,
	replaysOnErrorSampleRate: SAMPLE_RATE,
	integrations: IS_PRODUCTION ? [Sentry.replayIntegration()] : [],
	environment: PUBLIC_ENVIRONMENT
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
