const categories = [
	'juices',
	'sodas',
	'breakfast-bowls',
	'treats',
	'bagels',
	'hot-drinks',
	'cold-drinks',
	'pet-products',
	'gourmet-biscuits',
	'chocolate',
	'market'
];

export function match(param) {
	return categories.includes(param);
}
